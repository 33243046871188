<template>
  <div>
    <van-dialog v-model="show" title="标题" show-cancel-button>
      <tableLyz
        style="padding: 0 34px 0 24px;"
        :columns="columns"
        :list="data"
      >
        <template slot="nodata">暂无数据</template>
      </tableLyz>
    </van-dialog>
  </div>
</template>
<script>
export default {
  name: "index",
  data() {
    return {
      show: false
    };
  },
  computed() {},
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped></style>
